<script lang="ts">
	import { page } from '$app/stores';
	import { fadeInOnIntersect } from '@tickrr/ui/actions';
	import StripeSupportedPaymentProcessors from '@tickrr/ui/components/v2/modules/stripe/StripeSupportedPaymentProcessors.svelte';

	const FEATURES = [
		{
			icon: 'tabler:chart-line',
			label: 'Access to TickrrCharts&trade;'
		},
		{
			icon: 'solar:target-outline',
			label: 'Access to our powerful screener'
		},
		{
			icon: 'solar:documents-outline',
			label: 'Personalized market news'
		},
		{
			icon: 'solar:eye-outline',
			label: 'Customizable watchlists'
		},
		{
			icon: 'solar:bell-outline',
			label: 'Up to 20+ price alerts'
		},
		{
			icon: 'solar:chat-round-money-outline',
			label: 'Support for over 20,000+ symbols'
		},
		{
			icon: 'solar:bolt-bold',
			label: 'High-quality HLOCV data'
		},
		{
			icon: 'solar:clock-circle-outline',
			label: '10+ years historical data'
		},
		{
			icon: 'solar:money-bag-outline',
			label: 'Stocks, crypto, indices, & more'
		},
		{
			icon: 'solar:rocket-outline',
			label: 'And many more features!'
		}
	];
</script>

<!-- PRICING CARD -->
<div
	class="mx-auto flex w-full max-w-7xl flex-col-reverse laptop:flex-row laptop:rounded-3xl laptop:bg-surface-900 laptop:shadow-lg laptop:shadow-surface-500 laptop:ring laptop:ring-base"
	use:fadeInOnIntersect={{
		delay: 250,
		duration: 1250,
		translate: 'translate-y-8'
	}}
>
	<!-- LEFT COL -->
	<div
		class="max-laptop:mx-auto max-laptop:mt-8 max-tablet:max-w-xs tablet:text-center laptop:flex-auto laptop:p-8 laptop:text-left"
	>
		<h3 class="text-2xl font-bold tracking-tight text-surface-50">Monthly subscription</h3>
		<p class="mt-6 max-w-md text-lg leading-7 tablet:mx-auto laptop:mx-0">
			<!-- Trade smarter with Tickrr, the world's most advanced charting and market research
			platform. -->
			We believe in simple, transparent pricing. For that reason, we offer
			<a href="/features" class="anchor !text-inherit underline">full access</a> to all users of
			our platform for just one low price.
		</p>
		<div class="mt-10 flex items-center gap-x-4">
			<h4 class="flex-none font-semibold leading-6 text-primary-500">What’s included</h4>
			<div class="h-px flex-auto bg-surface-500"></div>
		</div>
		<ul role="list" class="mt-8 grid gap-4 leading-6 tablet:grid-flow-col tablet:grid-rows-5">
			{#each FEATURES as f (f)}
				<li class="flex items-center gap-x-3">
					<iconify-icon inline={true} icon={f.icon} />
					<!-- eslint-disable-next-line svelte/no-at-html-tags -->
					<span class="text-balance">{@html f.label}</span>
				</li>
			{/each}
		</ul>
	</div>

	<!-- RIGHT COL -->
	<div class="laptop:w-full laptop:max-w-lg laptop:flex-shrink-0 laptop:p-2">
		<div
			class="
			relative h-full overflow-hidden py-8 text-center text-base
			laptop:rounded-2xl laptop:py-16 laptop:ring laptop:ring-inset laptop:ring-surface-50
			"
		>
			<enhanced:img
				alt=""
				loading="eager"
				src="$images/landingpage/chart-page__full-screen.png?enhanced"
				class="absolute inset-0 hidden h-full object-cover opacity-50 blur-[2px] laptop:block"
			/>
			<div class="isolate flex h-full flex-col items-center justify-center">
				<span class="mb-2 text-lg">Tickrr Pro | Monthly</span>
				<span class="mb-2 font-sans text-5xl font-black text-surface-50 tablet:text-6xl"
					>14 days free</span
				>
				<span class="mb-8 text-lg">Then $39 USD per month</span>

				<a
					href={$page.data.profile ? '/checkout' : '/signup?redirectTo=/checkout'}
					class="variant-filled btn w-full max-w-xs flex-col gap-y-2 py-4 font-sans font-bold"
				>
					<span>Start your free trial</span>
					<!-- <StripeSupportedPaymentProcessors /> -->
				</a>
				<StripeSupportedPaymentProcessors classes="mt-3" />
			</div>
		</div>
	</div>
</div>
